<template>
    <LayoutNew>
        <h1 class="text-center">Collections</h1>
        <MDBCard id="CollectionsComponent" fluid>
            <MDBCardHeader class="p-4">
                <MDBInput placeholder="Search Collection" type="text" v-model="searchInput" inputGroup
                    :formOutline="false">
                    <MDBBtn color="primary" @click="createCollectionModal = true">
                        Create Collection
                    </MDBBtn>
                    <MDBBtn color="primary" @click="goToAsset">
                        Add Asset(s)
                    </MDBBtn>
                </MDBInput>
                <!-- create collection modal -->
                <MDBModal tag="form" id="createCollectionModal" tabindex="-1" labelledby="CreateCollectionLabel"
                    v-model="createCollectionModal">
                    <MDBModalHeader class="py-3 px-4">
                        <MDBModalTitle id="CreateCollectionLabel">
                            Create Collection
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody class="p-4 pt-3">
                        <p>You can manually add Assets to a Collection</p>
                        <MDBInput class="mb-3" placeholder="Enter Collection Name *" type="text"
                            v-model="collectionName" required invalidFeedback="Please provide your first name" />
                        <MDBInput placeholder="Enter Reference ID" type="text" v-model="referenceId" />
                    </MDBModalBody>
                    <MDBModalFooter class="py-3 gap-3">
                        <MDBBtn size="sm" @click="createCollectionModal = false">Close</MDBBtn>
                        <MDBBtn type="button" color="primary" size="sm" :disabled="collectionName == ''"
                            @click.prevent.stop="createCollection" v-if="!isCreateLoading">
                            Create Collection
                        </MDBBtn>
                        <MDBBtn color="primary" size="sm" disabled v-else>
                            <MDBSpinner class="loader" />
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBCardHeader>
            <!-- table -->
            <MDBCardBody class="overflow-auto" ref="el">
                <MDBTable striped hover class="align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col">Collection Name</th>
                            <th scope="col" width="150">Asset Count</th>
                            <th scope="col" width="150">Creation Date</th>
                            <th scope="col" width="150">Last Updated</th>
                            <th scope="col" width="200"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="row-action" v-for="(collection, index) in collectionsList" :key="index"
                            @click="goToCollectionsAssets(collection.collectionIdString)" title="view asset">
                            <td>{{ collection.title }}</td>
                            <td>{{ collection.assetsCount }}</td>
                            <td>{{ parseDateDashLong(collection.createdAt) }}</td>
                            <td>{{ collection.updatedAt ? parseDateDashLong(collection.updatedAt) : '-' }}</td>
                            <td>
                                <MDBBtn color="primary" size="sm"
                                    @click.prevent="goToCollectionsAssets(collection.collectionIdString)">
                                    View Assets
                                </MDBBtn>
                            </td>
                        </tr>
                        <tr v-if="isLoading">
                            <td colspan="5" class="text-center">
                                <MDBSpinner />
                            </td>
                        </tr>
                        <tr v-if="!isLoading && collectionsList.length === 0">
                            <td colspan="5" class="text-center">No collections found.</td>
                        </tr>
                    </tbody>
                </MDBTable>
            </MDBCardBody>
            <!-- toast -->
            <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
                color="success" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Success </template>
                Collection Created Successfully
            </MDBToast>
            <MDBToast v-model="toastError" :delay="2000" autohide position="top-right" appendToBody stacking
                color="danger" width="350px" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Failed </template>
                Collection Name already Exist!
            </MDBToast>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBTable,
    MDBInput,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSpinner,
    MDBToast,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { GetCollections } from "@/services/Collections/GetCollections";
import { PostCollection } from "@/services/Collections/PostCollection";
import { parseDateDashLong } from "@/helpers/parseDate";
import { useInfiniteScroll } from "@vueuse/core";
import { useTitle } from "@vueuse/core";

useTitle("Copyright - Collections | CreatorShield");

const searchInput = ref();
watch(searchInput, async (newVal) => {
    try {
        isLoading.value = true;
        if (newVal) {
            collectionsList.value = await getCollections(null, newVal);
        } else {
            collectionsList.value = await getCollections();
        }
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }

});

const createCollectionModal = ref(false);
const collectionName = ref("");
const referenceId = ref("");
const isCreateLoading = ref(false);

const createCollection = async () => {
    isCreateLoading.value = true;
    const formBody = {
        title: collectionName.value,
        referenceId: referenceId.value,
    };
    try {
        const response = await PostCollection(formBody);
        if (response.errors) {
            toastError.value = true;
        } else {
            createCollectionModal.value = false;
            toast.value = true;
            collectionsList.value = await getCollections();
        }
    } catch (error) {
        console.error(error);
        toastError.value = true;
    } finally {
        isCreateLoading.value = false;
    }
};

watch(createCollectionModal, () => {
    collectionName.value = "";
    referenceId.value = "";
});

const router = useRouter();
const goToCollectionsAssets = (id) => {
    router.push({ name: "CollectionsAssets", params: { id: id } });
};
const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
};

const collectionsList = ref([]);
const isLoading = ref(false);

const getCollections = async (lastItem, search) => {
    isLoading.value = true;
    const response = await GetCollections(lastItem, search);
    return response;
};

const toast = ref(false);
const toastError = ref(false);

onMounted(async () => {
    collectionsList.value = await getCollections();
    isLoading.value = false;
});

const el = ref();
const stopScrollLoad = ref(false);
useInfiniteScroll(
    el,
    async () => {
        if (
            isLoading.value ||
            stopScrollLoad.value ||
            collectionsList.value.length === 0
        ) {
            return;
        }
        isLoading.value = true;
        const currentArr = [...collectionsList.value];
        const lastItem = currentArr.pop();
        const response = await GetCollections(
            lastItem.collectionIdString,
            searchInput.value
        );
        if (response.length === 0) {
            stopScrollLoad.value = true;
            isLoading.value = false;
            return;
        }
        collectionsList.value = collectionsList.value.concat(response);
        isLoading.value = false;
    },
    { distance: 10 }
);
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.loader {
    width: 1rem;
    height: 1rem;
}
</style>