import { HttpClient } from "../HttpClient";
/**
 * * Get Collections
 */
export const GetCollections = async (lastItem,searchString) => {
	try {
		const { data } = await HttpClient.get("api/pexcollections/list", {
			params: {
				...(lastItem ? { maxId: lastItem } : {}),
				...(searchString ? { searchString: searchString } : {}),
				count: 10
			},
		});
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
